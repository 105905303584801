@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .already {
    text-align: center;
    .link {
      text-decoration: none;
      color: #2B5DA7;
    }
  }
  .mobileSignup {
    display: none;
  }
  .backArrowContainer {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0px 3px 20px #00000029;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .Reset {
    display: flex;
    height: 100%;
    background-color: white;
    .div1 {
      background-color: #F3F9FD;
      height: 100vh;
      flex-basis: 45%;
      background-image: url("../../Assets/extra/Feather\ BG.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      .picture {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        img {
          width: 70%;
        }
        h4 {
          text-align: center;
          margin-top: 30px;
          font: normal normal 550 30px/40px "poppins";
          color: #1a182b;
          text-transform: capitalize;
        }
      }

      .div1Logo {
        padding:1rem;
        img {
          width: 100px;
          float: right;
        }
      }
    }
    .div2 {
      flex-basis: 55%;
      display: flex;
      align-items: center;
      padding-left: 50px;
      .right {
        width: 85%;
        .headings {
          h3 {
            font-family: "Poppins", sans-serif;
            color: #1a182b;
            font-weight: 600;
            margin: 0;
          }
          p {
            font-family: "Poppins", sans-serif;
            color: #939298;
            font-weight: normal;
            margin: 20px 0;
          }
        }
        .mobile {
          label {
            display: block;
            font: normal normal normal 15px "poppins";
            letter-spacing: 0px;
            color: #1a182b;
            font-size: 15px;
            margin-bottom: 15px;
          }
          .password {
            width: 100%;
            padding: 10px 10px;
            border: 1px solid #cac6d4;
            border-radius: 5px;
            &:focus {
              border: 1px solid #cac6d4;
              outline: none;
            }
          }
        }
        .button {
          margin: 30px 0;
          button {
            width: 100%;
            background-color: #2B5DA7;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 5px;
            color: #ffffff;
            border: none;
            outline: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */
  .mobile {
    min-height: 75vh;
    background-color: #ffffff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 20px 20px 0 20px;
  }
  .headings {
    margin-left: 20px;
    padding-top: 50px;
    padding-bottom: 30px;
    h3 {
      font-weight: 300 !important;
    }
    p {
      font-size: 13px !important;
    }
  }
  .already {
    display: none;
  }
  .mobileSignup {
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    padding: 40px 0;
    p {
      color: #5f5982;
    }
    span {
      color: #f68a1e;
    }
  }
  .backArrowContainer {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 1rem;
    background-color: #ffffff;
    box-shadow: 0px 3px 20px #00000029;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .Reset {
    background-color: #232d78;
    .div1 {
      display: none;
    }
    .div2 {
      section {
        h3 {
          text-align: left;
          font: normal normal 600 20px "poppins";
          letter-spacing: 0px;
          color: #ffffff;
          text-transform: capitalize;
          font-weight: 400  !important;
        }
        p {
          text-align: left;
          font: normal normal normal 12px "poppins";
          letter-spacing: 0px;
          color: #ffffffd9;
          width: 90%;
          font-weight: 300  !important;
        }
        label {
          font: normal normal normal 15px "poppins";
          letter-spacing: 0px;
          color: #1a182b;
        }
        input {
          width: 100%;
          height: 45px;
          padding-left: 10px;
          border: 1px solid #cac6d4;
          border-radius: 5px;
          opacity: 1;
        }
        input:focus {
          outline: none;
          border: 1px solid #cac6d4;
        }
        button {
          margin-top: 40px;
          width: 100%;
          height: 50px;
          background: #2B5DA7 0% 0% no-repeat padding-box;
          color: white;
          border: none;
          border-radius: 5px;
          opacity: 1;
        }
       
      }
    }
  }
}
