@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .main_container {
    background-color: transparent;
    padding: 2rem;

    .description {
      text-align: center;
      font-size: 12px;
      color: #727170;
    }

    .icons_row {
      text-align: center;
    }

    .educational_support {
      font-size: 12px;
      color: #727170;
      padding: 2rem;
      text-align: center;
    }
    .sanofi_logo {
      width: 120px;
      margin-top: 10px;
    }

    .powered_by {
      font-size: 12px;
      color: #727170;
      padding: 2rem;
      text-align: center;
    }
    .otb_logo {
      width: 50px;
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  .main_container {
    background-color: transparent;
    padding: 1rem;
    color: rgb(95, 92, 92);
    margin-top: 3rem;

    .description {
      text-align: center;
      font-size: 12px;
      color: #727170;
    }

    .icons_row {
      text-align: center;
    }

    .educational_support {
      font-size: 12px;
      color: #727170;
      margin-bottom: 70px;
      margin-top: 20px;
      text-align: center;
    }
    .sanofi_logo {
      width: 120px;
      margin-top: 10px;
    }

    .powered_by {
      font-size: 12px;
      color: #727170;
      margin-top: 20px;
      text-align: center;
    }
    .otb_logo {
      width: 50px;
      margin-top: 10px;
    }
  }
}
