@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
 
  .main_container {
    height: auto;
    background-color: #f3f5f7;
    border-radius: 1rem;
    h4{
      margin: 1rem;
      margin-top: 2rem;
      font-weight: 600;
      color: #19197d;
    }
    .flexSection {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 1rem;
      section {
        flex-basis: 45%;
        margin: 0.5rem;

      }
      input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        border: 1px solid #f3f5f7;
        opacity: 1;
      }
      input::placeholder{
        color: #dae5f0;
        font-size: 0.7rem;
      }
      input:focus {
        outline: none;
        border: 1px solid #f3f5f7;
      }
      textarea {
        width: 100%;
        padding-left: 10px;
        border: 1px solid #f3f5f7;
        opacity: 1;
      }
      textarea::placeholder{
        color: #dae5f0;
        font-size: 0.7rem;
        padding-top: 0.5rem;
      }

      textarea:focus {
        outline: none;
        border: 1px solid #f3f5f7;
      }

    }
    .button_sent {
      height: 40px;
      width: 4rem;
      background: #2b5da7 0% 0% no-repeat padding-box !important;
      color: white !important;
      border-radius: 5px;
      white-space: nowrap;
      font-size: 0.7rem;
      border: none !important;
      margin-bottom: 1rem;
      margin-left: 1.5rem;
      float: left;
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */
  .main_container {
    height: auto;
    background-color: #f3f5f7;
    border-radius: 1rem;
    h4{
      margin: 1rem;
      margin-top: 2rem;
      font-weight: 600;
      color: #19197d;
    }
    .flexSection {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 1rem;
      section {
        flex-basis: 45%;
        margin: 0.5rem;

      }
      input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        border: 1px solid #f3f5f7;
        opacity: 1;
      }
      input::placeholder{
        color: #dae5f0;
        font-size: 0.7rem;
      }
      input:focus {
        outline: none;
        border: 1px solid #f3f5f7;
      }
      textarea {
        width: 100%;
        padding-left: 10px;
        border: 1px solid #f3f5f7;
        opacity: 1;
      }
      textarea::placeholder{
        color: #dae5f0;
        font-size: 0.7rem;
        padding-top: 0.5rem;
      }

      textarea:focus {
        outline: none;
        border: 1px solid #f3f5f7;
      }

    }
    .button_sent {
      height: 40px;
      width: 4rem;
      background: #2b5da7 0% 0% no-repeat padding-box !important;
      color: white !important;
      border-radius: 5px;
      white-space: nowrap;
      font-size: 0.7rem;
      border: none !important;
      margin-bottom: 1rem;
      margin-left: 1.5rem;
      float: left;
    }
  }
}
