@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .already {
    text-align: center;
    .link {
      text-decoration: none;
      color: #2B5DA7;
    }
  }
  .mobileSignup {
    display: none;
  }
  .or {
    display: none;
  }
  .login {
    display: flex;
    height: 100%;
    background-color: white;
    .div1 {
      background-color: #F3F9FD;
      height: 100vh;
      flex-basis: 45%;
      background-image: url("../../Assets/extra/Feather\ BG.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      .picture {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        img {
          width: 70%;
        }
        h4 {
          text-align: center;
          margin-top: 30px;
          font: normal normal 550 30px/40px "poppins";
          color: #1a182b;
          text-transform: capitalize;
        }
      }

      .div1Logo {
        padding:1rem;
        img {
          width: 100px;
          float: right;
        }
      }
    }
    .div2 {
      flex-basis: 55%;
      display: flex;
      align-items: center;
      padding-left: 50px;
      .right {
        width: 85%;
        .headings {
          h2 {
            font-family: "Poppins", sans-serif;
            color: #1a182b;
            font-weight: 700;
            margin: 0;
          }
          p {
            font-family: "Poppins", sans-serif;
            color: #939298;
            font-weight: normal;
            margin: 20px 0;
          }
        }
        .mobile {
          label {
            display: block;
            font: normal normal normal 15px "poppins";
            letter-spacing: 0px;
            color: #1a182b;
            font-size: 15px;
            margin-bottom: 15px;
          }
          .email {
            width: 100%;
            padding: 10px 10px;
            border: 1px solid #cac6d4;
            border-radius: 5px;
            &:focus {
              border: 1px solid #cac6d4;
              outline: none;
            }
          }
          .input_icon_wrap {
            border: 1px solid #cac6d4;
            border-radius: 5px;
            width: 100%;  
            display: flex;
            flex-direction: row;
            padding-left: 2px;
            .input_with_icon {
              border: none;
              padding-left: 2px;
              color: black;
              padding: 10px;
              flex: 1;
              &:focus {
                border: none;
                outline: none;
              }
            }  
            .input_icon {
              padding: 10px;
              color: gray;
            }
          }  
          .passwordLabel {
            width: 100%;
            margin-top: 30px;
            font-size: 15px;
            margin-bottom: 15px;
            p {
              font: normal normal normal 15px "poppins";
              letter-spacing: 0px;
              color: #1a182b;
            }
            span {
              font: normal normal normal 15px "poppins";
              color: #f68a1e;
              float: right;
            }
          }
        }
        .button {
          margin: 30px 0;
          button {
            width: 100%;
            background-color: #2B5DA7;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 5px;
            color: #ffffff;
            border: none;
            outline: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */
  .mobile {
    min-height: 75vh;
    background-color: #ffffff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 20px 20px 0 20px;
  }
  .headings {
    margin-left: 20px;
    padding-top: 70px;
    padding-bottom: 30px;
    h2 {
      font-weight: 700 !important;
      font-family: "Poppins", sans-serif;
      color: white;
    }
    p {
      font-size: 13px !important;
    }
  }
  .already {
    display: none;
  }
  .mobileSignup {
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    padding: 40px 0;
    p {
      color: #5f5982;
    }
    span {
      color: #f68a1e;
    }
  }
  .or {
    position: relative;
    height: 20px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    div {
      width: 100%;
      height: 1px;
      background-color: #cac6d4;
    }
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
      padding: 0 10px;
      color: #5f5982;
      font-family: "poppins", sans-serif;
      font-size: 14px;
    }
  }

  .login {
    background-color: #2B5DA7;
    .div1 {
      display: none;
    }
    .div2 {
      section {
        h3 {
          text-align: left;
          font: normal normal 600 20px "poppins";
          letter-spacing: 0px;
          color: #ffffff;
          text-transform: capitalize;
        }
        p {
          text-align: left;
          font: normal normal normal 12px "poppins";
          letter-spacing: 0px;
          color: #ffffffd9;
        }
        label {
          font: normal normal normal 15px "poppins";
          letter-spacing: 0px;
          color: #1a182b;
        }
        input {
          width: 100%;
          height: 45px;
          padding-left: 10px;
          border: 1px solid #cac6d4;
          border-radius: 5px;
          opacity: 1;
        }
        input:focus {
          outline: none;
          border: 1px solid #cac6d4;
        }
        .passwordLabel {
          width: 100%;
          margin-top: 30px;
          margin-bottom: 15px;
          p {
            font: normal normal normal 15px "poppins";
            letter-spacing: 0px;
            color: #1a182b;
          }
          span {
            font: normal normal normal 15px "poppins";
            color: #f68a1e;
            float: right;
          }
        }
        button {
          margin-top: 40px;
          width: 100%;
          height: 50px;
          background: #2B5DA7 0% 0% no-repeat padding-box;
          color: white;
          border: none;
          border-radius: 5px;
          opacity: 1;
        }
        span:nth-child(1) {
        }
        span:nth-child(2) {
          font: normal normal medium 20px/27px Poppins;
          letter-spacing: 0px;
          color: #f68a1e;
        }
        .input_icon_wrap {
          border: 1px solid #cac6d4;
          border-radius: 5px;
          width: 100%;  
          display: flex;
          flex-direction: row;
          padding-left: 2px;
          .input_with_icon {
            border: none;
            padding-left: 2px;
            color: black;
            padding: 10px;
            flex: 1;
            &:focus {
              border: none;
              outline: none;
            }
          }  
          .input_icon {
            padding: 10px;
            color: gray;
          }
        }  
        
        
      }
    }
  }
}
