@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .page13 {
    .bgImage {
      // background-position: center;
      height: 80vh;
      width: 85%;
      margin: auto;
      background-image: url("../../Assets//extra/Certificate-bg.svg");
      background-color: #2B5DA7;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      border-radius: 1rem;
      text-align: center !important;

      .greeting {
        color: white;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 500;
        margin-top: 2rem;
        padding-top: 9rem;
      }
      .status {
        color: white;
        text-align: center;
        padding-top: 2rem;
      }
      .subTitle {
        color: white;
        text-align: center;
        padding: 2rem;
      }
      .achievement {
        color: white;
        text-align: center;
        font-size: 3rem;
        font-weight: 700;
        padding-top: 0.4rem;
      }
      .certificate {
        color: white;
        background-color: #d5b07a;
        width: 25%;
        text-align: center;
        font-size: 1.1rem;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 400;
        border-radius: 3px;
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
    .certificateimg {
      margin: auto;
      text-align: center;
      height: 200px;
      margin-top: 1rem;
      .certificateimage {
        width: 200px;
        height: 200px;
        background-color: white;
        border-radius: 4px;
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  .page13 {
    .bgImage {
      height: 90vh;
      width: 100%;
      margin: auto;
      background-image: url("../../Assets//extra/Certificate-bg.svg");
      background-color: #2B5DA7;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-position: top;
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem;
      text-align: center !important;
      .greeting {
        color: white;
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        margin-top: 3rem;
        padding-top: 3rem;
        width: 90%;
        margin: auto;
      }
      .status {
        display: none;
      }
      .subTitle {
        color: white;
        text-align: center;
        padding-top: 2rem;
      }
      .achievement {
        color: white;
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        padding-top: 1rem;
        margin: auto;
        width: 90%;
      }
      .cong{
        display: flex;
        flex-direction: column-reverse;
      }
      .certificate {
        color: white;
        background-color: #d5b07a;
        width: 80%;
        text-align: center;
        font-size: 1.1rem;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 400;
        border-radius: 3px;
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
        // width: 90%;
      }
    }
    .certificateimg {
      margin: auto;
      text-align: center;
      height: 130px;
      margin-top: 5rem;
      .certificateimage {
        width: 140px;
        height: 140px;
        padding: 20px;
        background-color: white;
        border-radius: 4px;
      }
    }
  }
}
