@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET

  .main {
    display: flex;
    padding: 1rem;
    .main2 {
      padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .icon {
    display: none;
  }
  .courseDivButton2 {
    height: 40px;
    background: #2b5da7 0% 0% no-repeat padding-box !important;
    color: white !important;
    border-radius: 5px;
    white-space: nowrap;
    
  }
  .course {
    padding: 1rem;
    .courseDiv {
      display: flex;

      div {
        margin: 0 10%;
      }
      .spanText {
        font: normal normal medium 14px/19px "poppins";
        letter-spacing: 0px;
        color: #2b5da7;
      }
      h6 {
        width: 600px;
        font: normal normal 500 30px/40px "poppins";
        letter-spacing: 0px;
        color: #1a182b;
      }
      .courseDivButton1 {
        margin-top: 25px;
        color: #2b5da7;
        background: #e4f1fe 0% 0% no-repeat padding-box;
      }
      .courseDivButton2 {
        margin-top: 20px;
        height: 40px;
        width: 180px;
        background: #2b5da7 0% 0% no-repeat padding-box;
        color: white;
        border-radius: 5px;
        h5 {
          margin-top: 10px;
          font: normal normal medium "poppins";
          letter-spacing: 0px;
        }
      }
    }
    .frame {
      iframe {
        height: 440px;
      }
    }

    .about {
      background-color: white;
      padding: 30px;
      margin-top: 50px;

      h6 {
        font: normal normal 600 26px/35px "poppins";
        letter-spacing: 0px;
        color: #1a182b;
      }
      h6:nth-child(3) {
        margin-top: 50px;
        font: normal normal 600 26px/35px "poppins";
        letter-spacing: 0px;
        color: #1a182b;
      }
      p {
        font: normal normal normal 17px/35px "poppins";
        letter-spacing: 0px;
        color: #939298;
      }
      ul {
        margin: 20px 0 20px 30px;
        li {
          margin: -4px 0;
          font: normal normal normal 18px/36px "poppins";
          letter-spacing: 0px;
          color: #939298;
        }
      }
      span {
        font: normal normal normal 18px/36px "poppins";
        letter-spacing: 0px;
        color: #2b5da7;
      }
      .p1 {
        margin-top: 40px;
      }
      .p2 {
        margin-top: 30px;
      }
      .p3 {
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */

  .main {
    display: flex;
    padding: 10px 10px 70px 10px;
  }
  .courseDivButton2 {
    height: 40px;
    background: #2b5da7 0% 0% no-repeat padding-box !important;
    color: white !important;
    border-radius: 5px;
    white-space: nowrap;
  }
  .course {
    .backArrowContainer {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #ffffff;
      float: right;
      z-index: 10000;
      margin-top: 1rem;
      box-shadow: 0px 3px 20px #00000029;
      img {
        width: 15px;
        height: 15px;
      }
    }
    .courseDiv {
      display: flex;
      width: 100%;
      margin-top: 70px;

      div {
        // margin: 0 10%;
      }
      .spanText {
        font: normal normal medium 14px/19px "Poppins";
        letter-spacing: 0px;
        color: #2b5da7;
      }
      h6 {
        font: normal normal 600 24px/32px "Poppins";
        letter-spacing: 0px;
        color: #1a182b;
      }
      .courseDivButton1 {
        display: none;
      }
      .courseDivButton2 {
        bottom: 0%;
        left: 5% !important;
        margin-bottom: 10px;
        position: fixed;
        height: 50px;
        width: 90%;
        background: #2b5da7 0% 0% no-repeat padding-box;
        color: white;
        border-radius: 5px;
        h5 {
          margin-top: 10px;
          font: normal normal medium "poppins";
          letter-spacing: 0px;
        }
      }
    }
    .frame {
      margin-top: 20px;
      // margin-left: 1%;

      iframe {
        // height: 440px;
        width: 100%;
      }
    }

    .about {
      background-color: white;
      width: 100%;
      padding: 30px;
      margin-top: 50px;
      // margin-left: 1%;

      h6 {
        font: normal normal 600 26px/35px "poppins";
        letter-spacing: 0px;
        color: #1a182b;
        white-space: nowrap;
      }
      h6:nth-child(3) {
        margin-top: 50px;
        font: normal normal 600 26px/35px "poppins";
        letter-spacing: 0px;
        color: #1a182b;
      }
      p {
        font: normal normal normal 17px/35px "poppins";
        letter-spacing: 0px;
        color: #939298;
      }
      ul {
        // margin: 20px 0 20px 30px;
        li {
          margin: -4px 0;
          font: normal normal normal 18px/36px "poppins";
          letter-spacing: 0px;
          color: #939298;
        }
      }
      span {
        font: normal normal normal 18px/36px "poppins";
        letter-spacing: 0px;
        color: #2b5da7;
      }
      .p1 {
        margin-top: 40px;
        margin-bottom: -5px;
      }
      .p2 {
        margin-top: 30px;
        margin-bottom: -5px;
      }
      .p3 {
        margin-bottom: -5px;
      }
    }
  }
}
