@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .courseIntro {
    background-color: #f3f9fd;

    .courseDiv {
      display: flex;
      background-color: white;
      margin-top: 4%;
      padding: 20px;

      .courseDiv1 {
        background: #f9f9f9 0% 0% no-repeat padding-box;
        border-radius: 20px;
        border: 1px solid #7070701a;
        height: 500px;
        display: flex;
        justify-content: center;
        img {
          border: none;
          width: 90%;
          margin: auto;
          display: block;
        }
      }
      .courseDiv2 {
        margin-left: 30px;
        // display: inline-block; 
        h2 {
          font-family: "Poppins", sans-serif;
          color: #1a182b;
          font-weight: 700;
          margin: 0;
          padding-bottom: 2.5rem;
        }
        p {
          margin-top: 20px;
          font: normal normal normal 18px/30px "poppins";
          letter-spacing: 0px;
          color: #939298;
          width: 99%;
          font-size: 16px;
        }
        button {
          margin: 50px auto 0 auto;
          display: block;
          width: 40%;
          height: 50px;
          background: #2B5DA7 0% 0% no-repeat padding-box;
          color: white;
          border: none;
          border-radius: 5px;
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  .courseIntro {
    background-color: transparent;
    .courseDiv {
      background-color: white;
      width: 100%;
      border-radius: 30px;
      .courseDiv1 {
        display: none;
      }
      .courseDiv2 {
        width: 100%;
        padding: 30px 10px;
        h4 {
          font-size: 20px;
          color: #1a182b;
        }
        p {
          margin-top: 20px;
          font-size: 12px;
          line-height: 25px;
          color: #939298;
        }
        button {
          margin-top: 30px;
          width: 100%;
          height: 50px;
          background: #2B5DA7 0% 0% no-repeat padding-box;
          color: white;
          border: none;
          border-radius: 5px;
          opacity: 1;
        }
      }
    }
  }
}
