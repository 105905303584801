@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET

  .main {
    display: flex;
    padding: 1rem;
    .main2 {
      padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .icon {
    display: none;
  }
  .courseDiv {
    display: flex;

    .spanText {
      font: normal normal medium 14px/19px "poppins";
      letter-spacing: 0px;
      color: #2b5da7;
    }
    h6 {
      font: normal normal 500 25px/40px "poppins";
      letter-spacing: 0px;
      color: #1a182b;
    }
    .courseDivButton1 {
      color: #2b5da7;
      background: #e4f1fe 0% 0% no-repeat padding-box;
    }
    .courseDivButton2 {
      // left: 1rem;
      height: 40px;
      width: 11rem;
      background: #2b5da7 0% 0% no-repeat padding-box;
      color: white;
      border-radius: 5px;
      h5 {
        margin-top: 10px;
        font: normal normal medium "poppins";
        letter-spacing: 0px;
      }
    }
  }

  .drag {
    overflow: visible;
    display: none;
  }
  .courseIntro {
    width: 100%;
    padding-top: 1rem;
    .courseDi {
      background-color: white;
      width: 100%;
      padding: 20px;

      .courseDiv2 {
        h4 {
          font: normal normal 600 26px/35px "poppins";
          letter-spacing: 0px;
          color: #1a182b;
        }
        p {
          margin-top: 20px;
          font: normal normal normal 18px/36px "poppins";
          letter-spacing: 0px;
          color: #939298;
          font-size: 1rem;
        }
      }
    }

    .courseDiv3 {
      height: auto;
      margin-top: 2rem;
      background-color: white;
      padding: 2rem;
      display: flex;
      .courseDiv3_sec1 {
        width: 55%;
        text-align: center;
        height: auto;
        max-height: 20rem;
        background: #e4f1fe 0% 0% no-repeat padding-box;
        border: 2px dashed #2b5da7;
        img {
          margin-top: 10%;
          width: 100px;
          height: 100px;
        }
        P {
          margin: 20px auto 0 auto;
        }
        .files {
          color: #2b5da7;
        }
      }
      .courseDiv3_sec2 {
        padding-left: 2rem;
        align-items: center;
        justify-content: center;
        width: 45%;
        .button {
          margin-top: 2rem;
          background: #2b5da7 0% 0% no-repeat padding-box;
          border: none;
          height: 50px;
          padding-top: 10px;
          border-radius: 0.3rem;
          width: 50%;
          float: right;
          white-space: nowrap;
        }

        .button:hover::before {
          border: none;
        }
        .button:active {
          border: none;
        }
        .button:active::before {
          background: #2b5da7 0% 0% no-repeat padding-box;
          border: none;
        }
        section {
          width: 100%;
          height: auto;
          overflow: hidden;
          padding: 1rem;
          margin-top: 0.5rem;
          display: flex;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #f0f0f0;
          border-radius: 4px;
          img {
            margin-left: 7px;
            width: 50px;
            height: 50px;
          }
          strong {
            margin: 10px 10px 0 auto;
            img {
              width: 25px;
              height: 25px;
            }
          }
          .div3_title {
            margin-left: 70px;
            display: block;
            h6 {
              margin-left: -40px;
            }
            span {
              position: absolute;
              margin-top: -5px !important;
              margin-left: -40px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  /* phones */

  .main {
    display: block;
    padding: 10px;
    .main2 {
      // display: none;
      margin-top: 50px;
    }
  }
  .backArrowContainer {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #ffffff;
    float: right;
    z-index: 10000;
    margin-top: 1rem;
    box-shadow: 0px 3px 20px #00000029;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .drag {
    overflow: visible;
    display: none;
  }
  .courseDiv {
    display: block;
    width: 100%;
    margin-top: 30px;

    .spanText {
      font: normal normal medium 14px/19px "Poppins";
      letter-spacing: 0px;
      color: #2b5da7;
    }
    h6 {
      font: normal normal 600 24px/32px "Poppins";
      letter-spacing: 0px;
      color: #1a182b;
    }
    .courseDivButton1 {
      display: none;
    }
    .courseDivButton2 {
      bottom: 0%;
      margin-bottom: 10px;
      position: fixed;

      height: 50px;
      width: 95%;
      background: #2b5da7 0% 0% no-repeat padding-box;
      color: white;
      border-radius: 5px;
      h5 {
        margin-top: 10px;
        font: normal normal medium "poppins";
        letter-spacing: 0px;
      }
    }
  }

  .courseIntro {
    .courseDi {
      display: none;
    }
    .courseDiv3 {
      height: auto;
      padding: 1rem;
      padding-bottom: 2rem !important;
      width: 100%;
      margin-top: 30px;
      background-color: white;
      display: block;
      .courseDiv3_sec1 {
        margin: auto;
        width: 95%;
        text-align: center;
        height: auto;
        max-height: 15rem;
        background: #e4f1fe 0% 0% no-repeat padding-box;
        border: 2px dashed #2b5da7;
        img {
          margin-top: 10%;
          width: 100px;
          height: 100px;
        }
        P {
          margin: 20px auto 0 auto;
          width: 50%;
        }
        .files {
          color: #2b5da7;
        }
      }
      .courseDiv3_sec2 {
        text-align: center;
        .button {
          margin-top: 2rem;
          background: #2b5da7 0% 0% no-repeat padding-box;
          border: none;
          height: 50px;
          padding-top: 10px;
          border-radius: 0.3rem;
          width: 80%;
        }
        section {
          text-align: left !important;
          margin: auto;
          height: auto;
          overflow: hidden;
          margin-top: 20px;
          display: flex;
          background: #e4f1fe 0% 0% no-repeat padding-box;
          border: 1px solid #f0f0f0;
          border-radius: 4px;
          padding: 1rem;
          img {
            margin-left: 7px;
            width: 50px;
            height: 50px;
          }
          strong {
            margin: 10px 10px 0 auto;
            img {
              width: 25px;
              height: 25px;
            }
          }
          .div3_title {
            margin-left: 70px;
            display: block;
            h6 {
              margin-left: -40px;
            }
            span {
              position: absolute;
              margin-top: -5px !important;
              margin-left: -40px;
            }
          }
        }
      }
    }
  }
}
