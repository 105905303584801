* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
.parent_container{
  max-width: 1400px !important;
  margin: auto;
}
body {
  background-color: #f3f9fd;
}
p {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.616);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(74, 201, 180, 0.514);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(74, 201, 180, 1);
}
