@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET

  .login {
    display: flex;
    height: 100%;
    background-color: white;
    .div1 {
      background-color: #f3f9fd;
      height: 100vh;
      flex-basis: 45%;
      background-image: url("../../Assets/extra/Feather\ BG.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      .picture {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        img {
          width: 75%;
        }
        h4 {
          text-align: center;
          margin-top: 30px;
          font: normal normal 550 30px/40px "poppins";
          color: #1a182b;
          text-transform: capitalize;
        }
      }

      .div1Logo {
        padding: 1rem;
        img {
          width: 100px;
          float: right;
        }
      }
    }
    .div2 {
      flex-basis: 55%;
      display: flex;
      align-items: center;
      max-height: 100vh;
      overflow: auto;
      .right {
        margin: auto;

        .headings {
          margin-bottom: 1.8rem;
          h2 {
            font-family: "Poppins", sans-serif;
            color: #1a182b;
            font-weight: 700;
            font-size: 40px;
            margin: 0;
          }
        }
        .main_div1 {
          border: 2px solid #f3f9fd;
          margin-bottom: 2rem;
          .heading_div {
            background-color: #f3f9fd;
            padding: 0.5rem 0.5rem;
            p {
              text-align: center;
              font-size: 18px;
              font-family: "Poppins", sans-serif;
              color: #939298;
            }
          }
          .divider {
            display: flex;
            justify-content: space-between;
            padding: 1rem 1.8rem;
            width: 100%;
            .left {
              border: 1px solid #2b5da7;
              background-color: white;
              width: 47%;
              border-radius: 4px;
              text-align: center;
              p {
                line-height: 18px;
                padding: 1.2rem 0.5rem;
                color: #707070;
                letter-spacing: 0.55px;
              }
            }
            .right {
              border: 1px solid #2b5da7;
              background-color: white;

              width: 47%;
              text-align: center;
              align-items: center;
              vertical-align: middle;
              display: flex;
              justify-content: center;
              border-radius: 4px;
            }
            p {
              line-height: 15px;
              padding: 1.2rem 2rem;
              color: #707070;
              letter-spacing: 0.55px;
            }
          }
        }
        .main_div2 {
          border: 2px solid #f3f9fd;
          margin-bottom: 2rem;

          .heading_div {
            background-color: #f3f9fd;
            padding: 0.5rem 0.5rem;

            p {
              text-align: center;
              font-size: 18px;
              font-family: "Poppins", sans-serif;
              color: #939298;
            }
          }
          .divider {
            padding: 1rem 2rem;

            .div {
              border-radius: 4px;
              background-color: white;
              width: 100%;

              border: 1px solid #2b5da7;

              p {
                text-align: center;
                line-height: 18px;
                padding: 1.2rem 2rem;
                color: #707070;
                letter-spacing: 0.55px;
              }
            }
          }
        }
        .main_div3 {
          border: 2px solid #f3f9fd;
          // margin-bottom: 1rem;

          .heading_div {
            background-color: #f3f9fd;
            padding: 0.5rem 0.5rem;

            p {
              text-align: center;
              font-size: 18px;
              font-family: "Poppins", sans-serif;
              color: #939298;
            }
          }
          .divider {
            padding: 1.5rem 2rem;

            .div {
              border-radius: 4px;
              background-color: white;
              width: 100%;
              border: 1px solid #2b5da7;
              p {
                text-align: center;
                line-height: 18px;
                padding: 1.2rem 2rem;
                color: #707070;
                letter-spacing: 0.55px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */
  .login {
    display: block;
    height: 100%;
    background-color: #2b5da7;
    padding-bottom: 0rem;
    .div1 {
      display: none !important;
    }
    .div2 {
      flex-basis: 55%;
      display: flex;
      align-items: center;
      padding-left: 0px;
      .right {
        width: 100%;

        .headings {
          margin-left: 0px;
          padding-top: 70px;
          padding-bottom: 30px;
        }
        h2 {
          font-family: "Poppins", sans-serif;
          color: white;
          font-weight: 700 !important;
          font-family: "Poppins", sans-serif;
          font-size: 2rem;
          margin: 0;
          padding-left: 1rem;
        }
      }
      .bg_color {
        background-color: aqua;
        min-height: 80vh;
        background-color: #ffffff;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 20px 20px 40px 20px;
        .main_div1 {
          border: 2px solid #f3f9fd;
          margin-bottom: 2rem;
          border-radius: 15px;

          .heading_div {
            background-color: #f3f9fd;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;

            padding: 0.5rem 0.5rem;
            p {
              text-align: center;
              font-size: 18px;
              font-family: "Poppins", sans-serif;
              color: #939298;
            }
          }
          .divider {
            display: flex;
            flex-direction: column;

            justify-content: center;
            padding: 1rem 1.8rem;
            width: 100%;
            .left {
              border: 1px solid #ffffff;
              width: 100%;
              border-radius: 4px;
              text-align: center;
              margin-bottom: 1rem;
              p {
                line-height: 18px;
                padding: 1.2rem 0.5rem;
                color: #707070;

                letter-spacing: 0.55px;
              }
            }
            .right {
              border: 1px solid #ffffff;
              width: 100%;
              text-align: center;
              align-items: center;
              vertical-align: middle;
              display: flex;
              justify-content: center;
              border-radius: 4px;

              p {
                line-height: 18px;
                padding: 1.2rem 2rem;
                color: #707070;

                letter-spacing: 0.55px;
              }
            }
          }
        }
        .main_div2 {
          border: 2px solid #f3f9fd;
          margin-bottom: 2rem;
          border-radius: 15px;

          .heading_div {
            background-color: #f3f9fd !important;
            padding: 0.5rem 0.5rem;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;

            p {
              text-align: center;
              font-size: 18px;
              font-family: "Poppins", sans-serif;
              color: #939298;
            }
          }
          .divider {
            padding: 1rem 2rem;

            .div {
              border-radius: 4px;

              border: 1px solid #ffffff;

              p {
                text-align: center;
                line-height: 18px;
                padding: 1.2rem 2rem;
                color: #707070;

                letter-spacing: 0.55px;
              }
            }
          }
        }
        .main_div3 {
          border: 2px solid #f3f9fd;
          border-radius: 15px;
          // margin-bottom: 1rem;

          .heading_div {
            background-color: #f3f9fd;
            padding: 0.5rem 0.5rem;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;

            p {
              text-align: center;
              font-size: 18px;
              font-family: "Poppins", sans-serif;
              color: #939298;
            }
          }
          .divider {
            padding: 1.5rem 2rem;

            .div {
              border-radius: 4px;

              border: 1px solid #ffffff;

              p {
                text-align: center;
                line-height: 18px;
                padding: 1.2rem 2rem;
                color: #707070;

                letter-spacing: 0.55px;
              }
            }
          }
        }
      }
    }
  }
}
