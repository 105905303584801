@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET

  .main {
    display: flex;
    padding: 1rem;
    .main2 {
       padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .icon {
    display: none !important;
  }
  .courseDiv {
    display: flex;
    div {
      margin: 0 10%;
    }
    .spanText {
      font: normal normal medium 14px/19px "poppins";
      letter-spacing: 0px;
      color: #2B5DA7;
    }
    h6 {
      width: 600px;
      font: normal normal 500 30px/40px "poppins";
      letter-spacing: 0px;
      color: #1a182b;
    }

  }

  .courseIntro {
    padding-top: 1rem;
    .courseDi {
      display: flex;
      background-color: white;
      padding: 20px;

      .courseDiv1 {
        // background: #f9f9f9 0% 0% no-repeat padding-box;
        // border: 1px solid #7070701a;
        // padding: 20px;

        img {
          width: 100%;
        }
      }
      .courseDiv2 {
        padding: 1rem;
        h4 {
          font: normal normal 600 26px/35px "poppins";
          letter-spacing: 0px;
          color: #1a182b;
        }
        p {
          margin-top: 10px;

          font: normal normal normal 18px/30px "poppins";
          letter-spacing: 0px;
          color: #939298;
        }
        button {
          margin: 5% 0 0 0;
          padding: 10px 20px;
          height: 50px;
          background: #2B5DA7 0% 0% no-repeat padding-box;
          color: white;
          border: none;
          border-radius: 5px;
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  /* phones */

  .main {
    display: block;
    padding: 1rem;
    .main2 {
      padding-left: 1rem;
     padding-top: 1rem;
     padding-bottom: 1rem;
   }
  }

.backArrowContainer {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  float: right;
  z-index: 10000;
  margin-top: 1rem;
  box-shadow: 0px 3px 20px #00000029;
  img {
    width: 15px;
    height: 15px;
  }
}
  .courseDiv {
    display: flex;
    width: 100%;

    .spanText {
      font: normal normal medium 14px/19px "Poppins";
      letter-spacing: 0px;
      color: #2B5DA7;
    }
    h6 {
      font: normal normal 600 24px/32px "Poppins";
      letter-spacing: 0px;
      color: #1a182b;
    }
 
  }

  .courseIntro {
    width: 100% !important;
    margin-top: 7%;
    .courseDi {
      display: block;
      background-color: white;
      padding: 10px;

      .courseDiv1 {
        img {
          height: 100%;
          width: 100%;
        }
      }
      .courseDiv2 {
        h4 {
          margin-top: 30px;
          font: normal normal 600 20px/27px "Poppins";
          letter-spacing: 0px;
          color: #1a182b;
        }
        p {
          margin-top: 10px;

          font: normal normal normal 16px/30px "Poppins";
          letter-spacing: 0px;
          color: #939298;
        }

        button {
          margin: 5% 0 0 0;
          padding: 10px 20px;
          height: 50px;
          background: #2B5DA7 0% 0% no-repeat padding-box;
          color: white;
          border: none;
          border-radius: 5px;
          opacity: 1;
        }
      }
    }
  }
}
