@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET

  .sponser {
    margin-top: 20px;
    h4 {
      font: normal normal 500 28px/40px sans-serif;
      letter-spacing: 0px;
      color: #1a182b;
      margin-bottom: -10px;
    }

    .sponserDiv {
      display: flex;
      justify-content: space-between;
      width: 100%;
      p {
        font: normal normal normal 18px/24px sans-serif;
        letter-spacing: 0px;
        color: #6a707e;
        margin-bottom: 13px;
      }

      section {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cac6d4;
        border-radius: 4px;
        display: block;
        flex-wrap: wrap;
        text-align: center;
        margin-top: -10px;
        padding: 10px;
        h6 {
          margin-top: -5px;
          color: #525ca3;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  .sponser {
    margin-top: 20px;
    h4 {
      font: normal normal 500 28px/40px sans-serif;
      letter-spacing: 0px;
      color: #1a182b;
      margin-bottom: -10px;
    }

    .sponserDiv {
      .sponserDiv1 {
        display: inline-block;
        width: 50%;
        padding: 0 5px;
        p {
          font-size: 14px;
          letter-spacing: 0px;
          color: #6a707e;
          margin-bottom: 10px;
        }

        section {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #cac6d4;
          border-radius: 4px;
          width: 100%;
          text-align: center;
         
            img {
              width: 85%;
              height: 100%;
              margin: 0.5rem !important;
              position: relative;
            }
        }
      }
    }
  }
}
