@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .icon {
    display: none;
  }
  .mainContainer {
    background-color: #ffffff;
    width: 100%;
    min-height: 300px;
    max-height: 500;
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
    .chapterContainer {
      padding-top: 20px;
    }
    .selectedLessonMainContainer {
      border-bottom: 1px solid #f7f7f7;
      padding: 5px 0;
      .lessonContainer {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 12px 5px;
        border-radius: 7px;
        background-color: #D5B07A;
        .lessonCheck {
          width: 3rem;
          height: 2.5rem;
          clip-path: circle(50%);          
          background-color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          .playIcon {
            width: 10px;
            margin-left: 2px;
          }
        }
        .lesson {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
          .lessonNumber {
            p {
              color: #ffffff;
              font-size: 12px;
            }
          }
          .lessonName {
            p {
              color: #ffffff;
              font-size: 15px;
            }
          }
        }
        .lessonTimer {
          background-color: #ffffff;
          padding: 3px 8px;
          border-radius: 5px;
          display: flex;
          align-items: flex-start;
          height: 100%;
          p {
            color: #f68a1e;
            font-size: 10px;
          }
        }
      }
    }

    .lessonMainContainer {
      border-bottom: 1px solid #f7f7f7;
      padding: 5px 0;
      .lessonContainer {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 12px 5px;
        .lessonCheck {
          width: 3rem;
          height: 2.5rem;
          clip-path: circle(50%);        
          background-color: #e4f1fe;
          display: flex;
          justify-content: center;
          align-content: center;
          .playIcon {
            width: 10px;
            margin-left: 2px;
          }
        }
        .lesson {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
          .lessonNumber {
            p {
              color: #6a707e;
              font-size: 12px;
            }
          }
          .lessonName {
            p {
              color: #1a182b;
              font-size: 15px;
            }
          }
        }
        .lessonTimer {
          background-color: #d9f4ef;
          padding: 3px 8px;
          border-radius: 5px;
          display: flex;
          align-items: flex-start;
          height: 100%;
          // margin-top: -15px;
          p {
            color: #4ac9b4;
            font-size: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */
  .icon {
    margin: 20px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .mainContainerActive {
    right: 0 !important;
    transition: 0.3s all ease-in-out;
  }
  .mainContainer {
    background-color: #f3f9fd;
    width: 100%;
    padding: 0 20px;
    min-height: 100vh;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 1111;
    transition: 0.3s all ease-in-out;
    .chapterContainer {
      padding: 15px 0;
    }
    .selectedLessonMainContainer {
      padding: 10px 0;
      .lessonContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        padding: 15px 5px;
        border-radius: 7px;
        background-color: #D5B07A;
        .lessonCheck {
         
          background-color: #ffffff;
          width: 3rem;
          height: 2.5rem;
          clip-path: circle(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          .playIcon {
            width: 10px;
            margin-left: 2px;
          }
        }
        .lesson {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
          .lessonNumber {
            p {
              color: #ffffff;
              font-size: 12px;
            }
          }
          .lessonName {
            p {
              color: #ffffff;
              font-size: 15px;
            }
          }
        }
        .lessonTimer {
          background-color: #ffffff;
          padding: 5px 12px;
          border-radius: 5px;
          display: flex;
          align-items: flex-start;
          height: 100%;
          p {
            color: #f68a1e;
            font-size: 10px;
          }
        }
      }
    }
    .lessonMainContainer {
      padding: 10px 0;
      .lessonContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        padding: 15px 5px;
        border-radius: 7px;
        background-color: #ffffff;
        .lessonCheck {
          width: 3rem;
          height: 2.5rem;
          clip-path: circle(50%);
          background-color: #f2f4ff;
          display: flex;
          justify-content: center;
          align-items: center;
          .playIcon {
            width: 10px;
            margin-left: 2px;
          }
        }
        .lesson {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
          .lessonNumber {
            p {
              color: #939298;
              font-size: 12px;
            }
          }
          .lessonName {
            p {
              color: #1a182b;
              font-size: 15px;
            }
          }
        }
        .lessonTimer {
          background-color: #d9f4ef;
          padding: 5px 12px;
          border-radius: 5px;
          display: flex;
          align-items: flex-start;
          height: 100%;
          p {
            color: #4ac9b4;
            font-size: 10px;
          }
        }
      }
    }
  }
}
