@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET

  .main {
    display: flex;
    padding: 1rem;
    .main2 {
      padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .icon {
    display: none;
  }
  .courseIntro {
    padding-top: 1rem;

    .courseDiv4 {
      display: flex;
      padding: 15px 20px 0;
      width: 137%;
      height: 60px;
      background: #f0f0f0 0% 0% no-repeat padding-box;
      margin-top: 3rem;
      .bar {
        margin: 10px auto 0 auto;
        height: 7px;
        width: 70%;
        color: #2b5da7 !important;
        border-radius: 6px;
      }

      .courseDivButton1 {
        height: 33px;
        color: #2b5da7;
        background: #e4f1fe 0% 0% no-repeat padding-box;
      }
      .courseDivButton2 {
        height: 33px;
        width: 160px;
        background: #2b5da7 0% 0% no-repeat padding-box;
        color: white;
        border-radius: 5px;
        h5 {
          margin-top: 10px;
          font: normal normal medium "poppins";
          letter-spacing: 0px;
        }
      }
    }
    .courseDi {
      display: flex;
      background-color: white;
      text-align: center;
      padding-top: 2rem;
      justify-content: center;
      .courseDiv2Text {
        height: auto;
        margin-top: 5rem;
        margin-bottom: 5rem;
        & span {
          color: #d5b07a;
          font-weight: 600;
        }
        & h5 {
          font-weight: bolder;
          font-size: 1.5rem;
        }
        & h6 {
          font-weight: 500;
          font-size: 1.3rem;
        }
      }
      .courseDiv2_3 {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .selecttrue {
          height: 50px;
          width: 330px;
          margin: 20px 10px;
          text-align: center;
          border: 1px solid #cac6d4;
          border-radius: 4px;
          background-color: #4ac9b4;
          display: flex;
          p {
            padding-top: 7px;
            padding-left: 30px;
            font: normal normal normal 16px/36px "poppins";
            letter-spacing: 0px;
            color: white;
          }
          strong {
            margin-top: 7px;
            margin-left: 15px;
            height: 35px;
            width: 35px;
            background: white 0% 0% no-repeat padding-box;
            border-radius: 4px;
            padding-top: 4px;
          }
        }
        .selectfalse {
          height: 50px;
          width: 330px;
          margin: 20px 10px;
          text-align: center;
          border: 1px solid #cac6d4;
          border-radius: 4px;
          display: flex;
          p {
            padding-top: 7px;
            padding-left: 30px;
            font: normal normal normal 16px/36px "poppins";
            letter-spacing: 0px;
            color: #939298;
          }
          strong {
            margin-top: 7px;
            margin-left: 15px;
            height: 35px;
            width: 35px;
            background: #e4f1fe 0% 0% no-repeat padding-box;
            border-radius: 4px;
            padding-top: 4px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  /* phones */

  .main {
    display: block;
    padding: 10px 10px 70px 10px;
  
  }
  
.backArrowContainer {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  float: right;
  z-index: 10000;
  margin-top: 1rem;
  box-shadow: 0px 3px 20px #00000029;
  img {
    width: 15px;
    height: 15px;
  }
}
  .courseDiv4 {
    display: block;
    padding: 15px 20px 0;
    width: 100%;
    height: 50px;
    margin-top: -10px;
    margin-bottom: -30px;
    background: white;
    .bar {
      margin: 10px auto 0 auto;
      height: 7px;
      width: 90%;
      color: #2b5da7 !important;
      border-radius: 6px;
    }
    .courseDivButton1 {
      // height: 33px;
      // color: #525ca3;
      // background: #e4f1fe 0% 0% no-repeat padding-box;
      display: none;
    }
    .courseDivButton2 {
      margin-top: 2rem;
      height: 4rem;
      width: 100%;
      background: #525ca3 0% 0% no-repeat padding-box;
      color: white;
      border-radius: 5px;
      h5 {
        margin-top: 10px;
        font: normal normal medium "poppins";
        letter-spacing: 0px;
      }
    }
  }
  .courseIntro {
    width: 100% !important;
    margin-top: 7%;
    height: auto;
    .courseDi {
      margin-top: 50px;
      background-color: white;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      width: 100%;
      height: 100%;
      text-align: center;
      padding-top: 20px;
      justify-content: center;

      .courseDiv2Text {
        padding: 20px 0;
        width: 100%;
        span {
          font: normal normal normal 14px/36px "Poppins";
          letter-spacing: 0px;
          color: #ffffff;
        }
        h6 {
          margin: auto;

          font: normal normal 600 18px/24px "Poppins";
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .courseDiv2_3 {
        display: block;
        justify-content: center;
        margin-top: 20px;
        .selecttrue {
          height: 50px;
          width: 310px;
          margin: 20px 10px;
          text-align: center;
          border: 1px solid #cac6d4;
          border-radius: 4px;
          background-color: #4ac9b4;
          display: flex;
          p {
            padding-top: 7px;
            padding-left: 30px;
            font: normal normal normal 16px/36px "poppins";
            letter-spacing: 0px;
            color: white;
          }
          strong {
            margin-top: 7px;
            margin-left: 15px;
            height: 35px;
            width: 35px;
            background: white 0% 0% no-repeat padding-box;
            border-radius: 4px;
            padding-top: 4px;
          }
        }
        .selectfalse {
          height: 50px;
          width: 310px;
          margin: 20px 10px;
          text-align: center;
          border: 1px solid #cac6d4;
          border-radius: 4px;
          display: flex;
          p {
            padding-top: 7px;
            padding-left: 30px;
            font: normal normal normal 16px/36px "poppins";
            letter-spacing: 0px;
            color: #939298;
          }
          strong {
            margin-top: 7px;
            margin-left: 15px;
            height: 35px;
            width: 35px;
            background: #e4f1fe 0% 0% no-repeat padding-box;
            border-radius: 4px;
            padding-top: 4px;
          }
        }
        section {
          height: 60px;
          margin: 10px auto;
          width: 80%;
          text-align: center;
          border: 1px solid #cac6d4;
          border-radius: 4px;
          display: flex;
          p {
            padding-top: 7px;
            padding-left: 30px;
            font: normal normal normal 16px/36px "poppins";
            letter-spacing: 0px;
            color: #939298;
          }
          strong {
            margin-top: 7px;
            margin-left: 15px;
            height: 35px;
            width: 35px;
            background: #e4f1fe 0% 0% no-repeat padding-box;
            border-radius: 4px;
            padding-top: 4px;
          }
        }
      }

      .courseDiv2Text {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 20px 0;
        background-color: white;
        height: 100%;
        margin-top: 5rem;
        margin-bottom: 5rem;

        span {
          font: normal normal normal 14px/36px "Poppins";
          letter-spacing: 0px;
          color: #d5b07a;
        }
        h6 {
          margin: auto;

          font: normal normal 600 18px/24px "Poppins";
          letter-spacing: 0px;
          color: black;
        }
      }
    }
  }
}
