@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    height: 80px;
    padding: 0 40px;
    margin-bottom: 1rem;
    .logoContainer {
      width: 140px;
      cursor: pointer;
    }
    .backArrowContainer {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0px 3px 20px #00000029;
      img {
        width: 15px;
        height: 15px;
      }
    }
    .profileContainer {
      display: flex;
      align-items: center;
      .profileImage {
        width: 50px;
        height: 50px;
        background-color: #f3f9fd;
        border-radius: 50%;
        img {
          border-radius: 50%;
        }
      }
      .profileName {
        margin: 0 25px 0 15px;
        p {
          font-family: "poppins", sans-serif;
          font-size: 16px;
          color: #141d46;
          font-weight: normal;
        }
      }
      .dropdownContainer {
        .dropdown {
          background-color: #ffffff;
          border: none;
          outline: none;
          padding: 0;
          margin: 0;
          &:focus {
            border: none;
            outline: none;
            box-shadow: none;
          }
          &:active {
            border: none;
            outline: none;
            box-shadow: none;
          }
        }
        .dropdownMenu {
          padding: 0;
          margin: 0;
        }
        .dropdownItem {
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */

  .mainContainer {
    display: none;
  }
}
