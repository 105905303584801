@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET

  .react-player__preview {
    min-height: 35rem !important;
    
  }
}
@media only screen and (max-width: 767px) {
  /* phones */

  .react-player__preview {
    min-height: 12rem;
  }
}
