.loader {
  height: 80vh;
  text-align: center;
  margin-top: 130px;

  img {
    width: 170px;
  }

  .spinner {
    margin-top: 4rem;
    height: 70px;
    width: 70px;
    color: #525ca3 !important;
  }
  
}
