@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .mainContainer {
    width: 100%;
    height: 100%;
    background-color: rgba(20, 24, 51, 0.9);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 30rem;
    .countdownContainer {
      .headingContainer {
        text-align: center;
        margin-bottom: 30px;
        p {
          font-size: 28px;
          font-weight: 600;
        }
      }
      .countdown {
        display: flex;
        justify-content: center;
        .hourContainer {
          .hour {
            display: flex;
            .first {
              height: 90px;
              width: 5rem;
              background-color: #ddc095;
              border-radius: 6px;
              margin: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;

              position: relative;
              &::after {
                content: "";
                position: absolute;
                height: 50%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: #d5b07a;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
              }
              p {
                font-size: 40px;
                z-index: 11;
                font-weight: 600;
              }
            }
            .second {
              height: 90px;
              width: 5rem;
              background-color: #d5b07a;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                font-size: 40px;
              }
            }
          }
          .heading {
            text-align: center;
            margin-top: 20px;
            opacity: 0.8;
          }
        }
        .minutesContainer {
          .minutes {
            display: flex;
            .first {
              height: 90px;
              width: 5rem;
              background-color: #ddc095;
              border-radius: 6px;
              margin: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;

              position: relative;
              &::after {
                content: "";
                position: absolute;
                height: 50%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: #d5b07a;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
              }
              p {
                font-size: 40px;
                z-index: 11;
                font-weight: 600;
              }
            }
            .second {
              height: 90px;
              width: 5rem;
              background-color: #d5b07a;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                font-size: 40px;
              }
            }
          }
          .heading {
            text-align: center;
            margin-top: 20px;
            opacity: 0.8;
          }
        }
        .secondsContainer {
          .seconds {
            display: flex;
            .first {
              height: 90px;
              width: 5rem;
              background-color: #ddc095;
              border-radius: 6px;
              margin: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;

              position: relative;
              &::after {
                content: "";
                position: absolute;
                height: 50%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: #d5b07a;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
              }
              p {
                font-size: 40px;
                font-weight: 600;
                z-index: 11;
              }
            }
            .second {
              height: 90px;
              width: 5rem;
              background-color: #d5b07a;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                font-size: 40px;
              }
            }
          }
          .heading {
            text-align: center;
            margin-top: 20px;
            opacity: 0.8;
          }
        }
      }
      .descriptionContainer {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          opacity: 0.8;
          width: 70%;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */

  .mainContainer {
    width: 100%;
    height: 100%;
    background-color: rgba(20, 24, 51, 0.9);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 15rem;
    .countdownContainer {
      .headingContainer {
        text-align: center;
        margin-bottom: 30px;
        p {
          font-size: 22px;
          font-weight: 600;
        }
      }
      .countdown {
        display: flex;
        justify-content: center;
        .hourContainer {
          .hour {
            display: flex;
            .first {
              height: 50px;
              width: 4rem;
              background-color: #ddc095;
              border-radius: 6px;
              margin: 0.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                height: 50%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: #d5b07a;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
              }
              p {
                font-size: 20px;
                z-index: 11;
              }
            }
            .second {
              height: 50px;
              width: 4rem;
              background-color: #d5b07a;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                font-size: 20px;
              }
            }
          }
          .heading {
            text-align: center;
            margin-top: 10px;
            opacity: 0.8;
            font-size: 12px;
          }
        }
        .minutesContainer {
          .minutes {
            display: flex;
            .first {
              height: 50px;
              width: 4rem;
              background-color: #ddc095;
              border-radius: 6px;
              margin: 0.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                height: 50%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: #d5b07a;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
              }
              p {
                font-size: 20px;
                z-index: 11;
              }
            }
            .second {
              height: 50px;
              width: 4rem;
              background-color: #d5b07a;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                font-size: 20px;
              }
            }
          }
          .heading {
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
            opacity: 0.8;
          }
        }
        .secondsContainer {
          .seconds {
            display: flex;
            .first {
              height: 50px;
              width: 4rem;
              background-color: #ddc095;
              border-radius: 6px;
              margin: 0.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                height: 50%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: #d5b07a;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
              }
              p {
                font-size: 20px;
                z-index: 11;
              }
            }
            .second {
              height: 50px;
              width: 4rem;
              background-color: #d5b07a;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                font-size: 20px;
              }
            }
          }
          .heading {
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
            opacity: 0.8;
          }
        }
      }
      .descriptionContainer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          opacity: 0.8;
          font-size: 12px;
          width: 90%;
        }
      }
    }
  }
}
