@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .main_container {
    background-color: transparent;
    padding-bottom: 10px;

    .spanText {
      font: normal normal medium 14px/19px "poppins";
      letter-spacing: 0px;
      color: #2B5DA7;
    }
    .heading {
      font-size: 1.5rem;
    }
    .courseDivButton1 {
      float: left;
      margin-right: 1rem;
      color: #2B5DA7 !important;
      background: #e4f1fe 0% 0% no-repeat padding-box !important;
    }
    .courseDivButton2 {
      float: right;
      height: 40px;
      background: #2B5DA7 0% 0% no-repeat padding-box !important;
      color: white !important;
      border-radius: 5px;
      white-space: nowrap;
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */
  .main_container {
    background-color: transparent;

    padding-top: 1rem;

    .spanText {
      font: normal normal medium 14px/19px "poppins";
      letter-spacing: 0px;
      color: #2B5DA7;
    }
    .heading {
      font-size: 1.5rem;
    }
    .courseDivButton1 {
      display: none !important;
    }
    .courseDivButton2 {
      bottom: 0%;
      margin-bottom: 10px;
      position: fixed;
      width: 95%;
      margin-top: 20px;
      height: 50px;
      background-color: #2B5DA7;
      opacity: 1;
      color: white;
      border-radius: 5px;
      z-index: 1111111;
      white-space: nowrap;
    }
  }
}
