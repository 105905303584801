@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .already {
    text-align: center;
    margin-top: 10px !important;
    
  
    .link {
      text-decoration: none;
      color: #2B5DA7;
    }
  }
  .div2_intro {
    p {
      font-family: "Poppins", sans-serif;
      color: #939298;
      font-weight: normal;
      margin: 15px 0;
    }
    h2{
      font-family: "Poppins", sans-serif;
      color: #1a182b;
      font-weight: 700;
      margin-top: 5rem;
    }
  }
  .signup {
    display: flex;
    background-color: white;
    height: 100vh;
    .div1 {
      background-color: #f3f9fd;
      height: 100%;
      background-image: url("../../Assets/extra/Feather\ BG.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      flex-basis: 45%;
      position: relative;
      .picture {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 90%;
        img {
          width: 80%;
          
        }
        h4 {
          text-align: center;
          margin-top: 50px;
          color: #1a182b;
          text-transform: capitalize;
        }
      }
      
      .div1Logo {
        padding:1rem;

        img {
          width: 100px;
          float: right;
        }
      }
    }
    .div2 {
      flex-basis: 55%;
      display: flex;
      align-items: center;
      height: auto;
      background-color: #ffffff;
      .right {
        width: 90%;
        padding-left: 5%;
        // height: 80vh;
      }
      .flexSection {
        display: flex;
        justify-content: space-between;
        width: 100%;
        section {
          flex-basis: 45%;
        }
        input {
          width: 100%;
          height: 40px;
          padding-left: 10px;
          border: 1px solid #cac6d4;
          border-radius: 5px;
          opacity: 1;
        }

        input:focus {
          outline: none;
          border: 1px solid #cac6d4;
        }
        .flexSection2 {
          select {
            width: 100%;
            height: 40px;
            padding-left: 10px;
            border: 1px solid #cac6d4;
            border-radius: 5px;
            opacity: 1;
          }
        }
      }
      .flexSectionNumber {
        input {
          width: 100%;
        }

        input:focus {
          outline: none;
          border: 1px solid #cac6d4;
        }
      }
      .flexSection3 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        section {
          flex-basis: 45%;
        }

        .flexSection3_1 {
          input {
            width: 100%;
            height: 40px;
            padding-left: 10px;
            border: 1px solid #cac6d4;
            border-radius: 5px;
            opacity: 1;
          }

          input:focus {
            outline: none;
            border: 1px solid #cac6d4;
          }
        }
        .flexSection3_2 {
          input {
            width: 100%;
            height: 40px;
            padding-left: 10px;
            border: 1px solid #cac6d4;
            border-radius: 5px;
            opacity: 1;
          }
          .input_icon_wrap {
            border: 1px solid #cac6d4;
            border-radius: 5px;
            width: 100%;
            display: flex;
            flex-direction: row;
            padding-left: 2px;
            .input_with_icon {
              border: none;
              padding-left: 2px;
              flex: 1;
              padding: 10px;
              color: black;
              &:focus {
                border: none;
                outline: none;
              }
            }
            .input_icon {
              color: gray;
            }
            .input_icon {
              margin: auto;
              margin-right: 10px;
            }
          }

          input:focus {
            outline: none;
            border: 1px solid #cac6d4;
          }
        }
      }
      section {
        margin: 4px 0;
        display: block;
        h3 {
          text-align: left;
          font: normal normal 600 40px/50px "poppins";
          letter-spacing: 0px;
          color: #1a182b;
          text-transform: capitalize;
        }
        p {
          text-align: left;
          font: normal normal normal 18px/36px "poppins";
          letter-spacing: 0px;
          color: #939298;
        }
        input {
          // width: 100%;
          height: 40px;
          padding-left: 10px;
          border: 1px solid #cac6d4;
          border-radius: 5px;
          opacity: 1;
        }

        input:focus {
          outline: none;
          border: 1px solid #cac6d4;
        }

        button {
          margin-top: 20px;
          width: 100%;
          height: 50px;
          background: #2B5DA7 0% 0% no-repeat padding-box;
          color: white;
          border: none;
          border-radius: 5px;
          opacity: 1;
          margin-bottom: 2rem;
        }
        
        span:nth-child(2) {
          font: normal normal medium 20px/27px "poppins";
          letter-spacing: 0px;
          color: #2B5DA7;
        }
      }
      .or {
        display: none;
      }
      .buttongoogle {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  /* phones */
  .already {
    background-color: #ffffff;
    text-align: center;
    font-size: 13px;
    .link {
      text-decoration: none;
      color: #2B5DA7;
    }
  }
  .button {
    background-color: #ffffff !important;
    padding: 0 20px !important;
    button {
      margin: 0 !important;
    }
  }
  .signup {
    display: flex;
    height: 100%;
    background-color: white;
    .div1 {
      display: none;
    }
    .div2 {
      margin: auto;
      display: block;
      background-color: #232d78;
      .div2_intro {
        margin-left: 20px;
        padding-top: 70px;
        padding-bottom: 30px;
        h3 {
          font-family: "Poppins", sans-serif;
          color: #ffffff;
          font-weight: 300 !important;
          margin: 0;
          font-size: 20px;
        }
        p {
          font-size: 13px !important;
          font-family: "Poppins", sans-serif;
          color: #939298;
          font-weight: normal;
          margin-top: 20px;
        }
      }
      .set {
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
        background-color: #ffffff;
      }
      .flexSection {
        display: block;
        width: 100%;
        input {
          width: 100%;
          height: 50px;
          padding-left: 10px;
          border: 1px solid #cac6d4;
          border-radius: 5px;
          opacity: 1;
        }

        input:focus {
          outline: none;
          border: 1px solid #cac6d4;
        }
        .flexSection2 {
          select {
            width: 100%;
            height: 50px;
            padding-left: 10px;
            border: 1px solid #cac6d4;
            border-radius: 5px;
            opacity: 1;
          }
        }
      }
      .flexSectionNumber {
        input {
          width: 100%;
        }

        input:focus {
          outline: none;
          border: 1px solid #cac6d4;
        }
      }
      .flexSection3 {
        display: block;
        justify-content: space-between;
        .flexSection3_1 {
          input {
            width: 100%;
            height: 50px;
            padding-left: 10px;
            border: 1px solid #cac6d4;
            border-radius: 5px;
            opacity: 1;
          }

          input:focus {
            outline: none;
            border: 1px solid #cac6d4;
          }
        }
        .flexSection3_2 {
          input {
            width: 100%;
            height: 50px;
            padding-left: 10px;
            border: 1px solid #cac6d4;
            border-radius: 5px;
            opacity: 1;
          }

          input:focus {
            outline: none;
            border: 1px solid #cac6d4;
          }
        }
      }
      section {
        padding: 20px;

        display: block;

        input {
          width: 100%;
          height: 50px;
          padding-left: 10px;
          border: 1px solid #cac6d4;
          border-radius: 5px;
          opacity: 1;
        }

        input:focus {
          outline: none;
          border: 1px solid #cac6d4;
        }

        button {
          margin-top: 20px;
          width: 100%;
          height: 50px;
          background: #2B5DA7 0% 0% no-repeat padding-box;
          color: white;
          border: none;
          border-radius: 5px;
          opacity: 1;
        }
       
        span:nth-child(2) {
          font: normal normal medium 20px/27px "poppins";
          letter-spacing: 0px;
          color: #2B5DA7;
        }
        .buttongoogle {
          margin-top: 20px;
          width: 100%;
          height: 50px;
          background: white 0% 0% no-repeat padding-box !important;
          color: gray;
          border:1px solid gray;
          border-radius: 5px;
          opacity: 1;
        
        }
        .input_icon_wrap {
          border: 1px solid #cac6d4;
          border-radius: 5px;
          width: 100%;
          display: flex;
          flex-direction: row;
          padding-left: 2px;
          .input_with_icon {
            border: none;
            padding-left: 2px;
            color: black;
            padding: 10px;
            flex: 1;
            &:focus {
              border: none;
              outline: none;
            }
          }
          .input_icon {
            color: gray;
          }
          .input_icon {
            margin: auto;
            margin-right: 10px;
          }
        }
        
      }
      .or {
        position: relative;
        height: 20px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        div {
          width: 85%;
          margin: auto;
          height: 1px;
          background-color: #cac6d4;
        }
        p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #ffffff;
          padding: 0 10px;
          color: #5f5982;
          font-family: "poppins", sans-serif;
          font-size: 14px;
        }
      }
    }
  }
}
